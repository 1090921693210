<template>
  <div>Company Show {{ $route.query }}</div>
</template>

<script>
export default {
  data() {
    return {

    }
  }
}
</script>
